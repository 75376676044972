// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bar_DvF48,.container_nurwy,.label_NLuEs{display:flex;height:100%;position:absolute;width:100%}.container_nurwy{border-right:1.25rem solid transparent;height:calc(100% - 1rem);margin-top:.5rem}.label_NLuEs{align-items:center;background-color:transparent;background-color:initial;justify-content:center}.table-cell-bar-label>*{opacity:.4}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bar": "bar_DvF48",
	"container": "container_nurwy",
	"label": "label_NLuEs"
};
module.exports = ___CSS_LOADER_EXPORT___;
